<template>
  <div>
    <Navbar page="Templates" link="/diagnosticos" nameLink="Diagnósticos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <router-link :to="`${route}/form`" type="button" 
        class="inline-block transition duration-200 bg-red-600 hover:bg-red-700 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Criar novo diagnóstico</span>
      </router-link>

      <div class="col-span-12 2xl:col-span-4 flex py-2">
          <input 
            type="checkbox" 
            v-model="arquivados" 
            @change="updateFiltro" 
            class="rounded-sm" 
            id="visualizarArquivados"
          />
          <label for="visualizarArquivados" class="block text-sm font-medium ml-2">
              Visualizar arquivados
          </label>
      </div>
      
      <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length && !arquivados" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2 md:ml-4">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Tema
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Inclui PAI
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.tema }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.incluirPAI ? 'Sim': 'Não' }}
                          </div>
                        </div>
                      </td>
                      <td class="flex flex-wrap px-6 py-4 text-sm font-medium gap-0.5 items-center">
                          <router-link 
                            type="button" 
                            :to="`${route}/form/${item._id}`" 
                            class="text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mx-1 ">
                              Editar
                          </router-link>
                          <button
                            v-if="!$store.state.user.cliente && !arquivados"
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 ">
                            Arquivar
                          </button>
                          <button
                            v-else-if="!$store.state.user.cliente && arquivados"
                            @click="ativar(item._id)" 
                            type="button" 
                            class="text-white bg-green-600 hover:bg-green-700 rounded py-1 px-4 mx-1 ">
                            Ativar
                          </button>
                          <button
                            v-if="arquivados"
                            @click="removeArquivada(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 ">
                            Excluir
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: '/diagnosticos/gerenciar',
      form: {
        responsavel: null,
        fornecedor: null,
        tratamento: '',
      },
      arquivados: false,
      pessoas: [],
      fornecedores: [],
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      await this.$http.post(`/v1/diagnosticosBase/list`, { 
        arquivados: this.arquivados, 
        skip: this.query.skip, 
        limit: this.query.limit, 
        page: this.query.page  
      })
      .then((resp)=>{
        this.list = resp.data.itens;
        this.total = resp.data.total;
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/diagnosticosBase/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Arquivadas com sucesso!");
          this.start();
        }
      })
    },

    async removeArquivada (id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja excluir esse diagnóstico arquivado?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticosBase/remover/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticosBase/${id}`)
              .then(()=>{
                this.$vToastify.success("Arquivado com sucesso!");
                this.page = 1;
                this.start();
              }).catch(()=> this.$vToastify.success("Diagnóstico não arquivado!"))
              
            }
          }
      });
    },

    async ativar(id) {
    this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja ativar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.put(`/v1/diagnosticosBase/`, { _id: id, ativo: true })
              .then((resp)=>{
                this.$vToastify.success(resp.data.msg);
                this.page = 1;
                this.start();
              })
              .catch(()=> this.$vToastify.error('Erro ao ativar item.'))
            }
          }
      });
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        arquivados: this.arquivados 
      };
      this.$router.push({ path: `${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;
      this.start();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>